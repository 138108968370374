import api from '../../apis/api';

const headerWithKey = { 'X-API-KEY': process.env.REACT_APP_APIGEE_CLIENT_ID };

export const CovidSurveyFormApi = {
    /**
     * @param disasterType string
     * @param data object
     * @returns {Promise<number>} surveyFormId
     */
    saveSurveyData: (disasterType, data) => {
        return new Promise((resolve, reject) => {
            data.disasterType = disasterType;
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/${disasterType}/surveyform`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey },
                body: JSON.stringify(data) // we need to do another stringify to escape quotes as we are sending the json value as a string
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    getServices: (id, email) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/summary?surveyFormId=${id}&email=${email}&disasterType=covid`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey }
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    savePersonalDetails: (data, id) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/subscribe?surveyFormId=${id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey },
                body: JSON.stringify(data) // we need to do another stringify to escape quotes as we are sending the json value as a string
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    sendSummarySurveyFormEmail: (id, email) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/covid/summary/${id}/email?emailAddress=${email}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey }
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.status);
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    verifyGoogleRecaptchaV3: (recaptchaToken, action) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/v3/verify-google-recaptcha?recaptchaToken=${recaptchaToken}&action=${action}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey }
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    verifyGoogleRecaptchaV2: (recaptchaToken, action) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/v2/verify-google-recaptcha?recaptchaToken=${recaptchaToken}&action=${action}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey }
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    resubscribe: (disasterType, surveyFormId) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/${disasterType}/subscription/${surveyFormId}?subscribe=true`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey }
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.status);
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    unsubscribe: (disasterType, surveyFormId) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/${disasterType}/subscription/${surveyFormId}?subscribe=false`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey }
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.status);
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },
    updateSuburbLocation: async (id, body) => {
        try {
            return await api.patchSecure(`/covid/summary/${id}`, body);
        } catch (error) {
            return null;
        }
    },

    updateSurveyData: (id, disasterType, data) => {
        return new Promise((resolve, reject) => {
            const newData = { ...data, disasterType };
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/${disasterType}/summary/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey },
                body: JSON.stringify(newData)
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    }
};
