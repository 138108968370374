import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { ErrorMessage, Form, Formik } from 'formik';
import Details from './Details';
import Category from '../../common/category/Category';
import { SurveyFormApi } from '../SurveyFormApi';
import ThumbsUpThumbsDown from '../../thumbs-up-thumbs-down/ThumbsUpThumbsDown';
import Notice from '../../Notice';
import Header from '../../header/Header';
import NaturalDisastersNotice from '../NaturalDisastersNotice';
import QuestionAnswers from '../QuestionAnswers/QuestionAnswers';
import { pluralise, smoothScrollTo } from '../../../common';
import './ServiceCategories.scss';

class ServiceCategories extends Component {
    static displayName = 'ServiceCategories';

    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.object,
        location: PropTypes.object
    };

    static defaultProps = {
        match: void 0,
        location: void 0
    };

    constructor (props) {
        super(props);

        this.state = {
            summary: [],
            id: '',
            email: '',
            subscribeSuccessful: null,
            totalNewServices: 0,
            emailSent: false,
            emailConfirmation: false,
            expandedServiceCategoryNames: [],
            questionAnswers: [],
            personalisedSupport: null,
            salesforceStatus: false
        };
    }

    componentDidMount = async () => {
        const { history, match, location } = this.props;
        const { id, email } = match.params;
        const subscribeSuccessful = get(location, 'state.subscribeSuccessful', null);
        const personalisedSupport = get(location, 'state.personalisedSupport', null);

        try {
            const decodedEmail = email ? atob(email) : '';
            const result = await SurveyFormApi.getServices(id, decodedEmail);

            this.setState({
                summary: result.availableServices,
                id,
                email: decodedEmail,
                subscribeSuccessful,
                subscribedForUpdates: result.subscribedForUpdates,
                totalNewServices: result.totalNewServices,
                emailSent: result.emailSent,
                questionAnswers: JSON.parse(result.questionAnswers),
                salesforceStatus: result.salesforceStatus,
                personalisedSupport
            }, () => {
                setTimeout(() => {
                    smoothScrollTo();
                }, 500);
            });
        } catch (e) {
            history.push('/error');
        }
    };

    sendResultsEmail = async (values) => {
        try {
            const result = await SurveyFormApi.sendServicesOfferingEmail(this.state.id, values.emailAddress);
            if (result === 200) {
                this.setState({ emailSent: true, emailConfirmation: true, email: values.emailAddress });
            }
        } catch (e) {
            this.props.history.push('/error');
        }
    };

    validateEmail = (email) => {
        // TODO: get rid of this warning
        // 90:20  warning  Unsafe Regular Expression  security/detect-unsafe-regex
        // eslint-disable-next-line security/detect-unsafe-regex
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    validate = (values) => {
        const errors = {};

        if (!this.validateEmail(values.emailAddress.trim())) {
            errors.emailAddress = 'Please enter a valid email address';
        }

        return errors;
    };

    onCollapsibleOpening = (category) => () => {
        const { expandedServiceCategoryNames } = this.state;

        if (!expandedServiceCategoryNames.includes(category.category)) {
            this.setState({
                expandedServiceCategoryNames: [
                    ...expandedServiceCategoryNames,
                    category.category
                ]
            });
        }
    };

    onCollapsibleClosed = (category) => () => {
        const { expandedServiceCategoryNames } = this.state;

        if (expandedServiceCategoryNames.includes(category.category)) {
            this.setState({
                expandedServiceCategoryNames: expandedServiceCategoryNames.filter(name => name !== category.category)
            });
        }
    };

    render () {
        const {
            id,
            email,
            summary,
            subscribeSuccessful,
            totalNewServices,
            emailSent,
            emailConfirmation,
            expandedServiceCategoryNames,
            personalisedSupport
        } = this.state;
        const parsedSupport = personalisedSupport ? personalisedSupport.toLowerCase() : '';

        return (
            <Fragment>
                <Header title="Disaster Assistance Finder" />
                <div className="container">
                    { subscribeSuccessful && parsedSupport && (
                        <Notice
                            title="Your request for personalised support was successfully submitted"
                            message={`One of our Customer Care representatives will be in contact with you via ${parsedSupport} as soon as possible.`}
                            type="success"
                        />
                    )}

                    <NaturalDisastersNotice />

                    { totalNewServices > 0 &&
                        <Notice message={`${totalNewServices} new assistance ${pluralise('item', totalNewServices)} you may be eligible for have been added since your last visit.`} />
                    }

                    <section className="results-page">
                        <div className="grid__container">
                            <div className="grid__9-col">
                                <div className="summary-header">
                                    <b>Reference number: {id}</b>

                                    <h1 style={{ marginTop: '0.5rem' }}>Based on what you've told us, you may be eligible for the following assistance.</h1>
                                    <hr className="spacer" />

                                    <Formik
                                        validate={this.validate}
                                        initialValues={{ emailAddress: '' }}
                                        onSubmit={this.sendResultsEmail}
                                    >
                                        {({ values, isSubmitting, handleChange }) => (
                                            <Form>
                                                {!emailSent &&
                                                <div className="page-sub-section send-email">
                                                    <h3>Want to save your assistance list for later?</h3>
                                                    We’ll email you a link to this page so you can access
                                                    your results at a later time.

                                                    <div className="form__input-group">
                                                        <div className="form__item">
                                                            <label htmlFor="emailAddress">Email Address</label>
                                                            <input type="text" id="emailAddress" onChange={handleChange} value={values.emailAddress}/>
                                                        </div>
                                                        <div className="form__actions">
                                                            <button className="button button--tertiary" type="submit" disabled={isSubmitting}>
                                                                <span className="hide-btn-text">Email Results</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage className="form__error" name={'emailAddress'} component={'div'}/>
                                                </div>
                                                }
                                            </Form>
                                        )}
                                    </Formik>

                                    {emailConfirmation && <div className="subscribe-success" style={{ marginTop: '1rem' }}>
                                        Your results link was successfully emailed to {email}
                                    </div>}

                                </div>

                                <ul className="product-categories">
                                    {summary
                                        .sort((service1, service2) => service1.rank - service2.rank)
                                        .map(category => {
                                            return (
                                                <li key={category.category} className="category-container">
                                                    {category.services
                                                        ? <Collapsible
                                                            trigger={<Category category={category}/>}
                                                            tabIndex={0}
                                                            onOpening={this.onCollapsibleOpening(category)}
                                                            onClose={this.onCollapsibleClosed(category)}
                                                        >
                                                            <Details
                                                                services={category.services}
                                                                categoryName={category.category}
                                                                expandedServiceCategoryNames={expandedServiceCategoryNames}
                                                            />
                                                        </Collapsible>
                                                        : <Category category={category}/>
                                                    }
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                                <div className="other-assistance-finders">
                                    <div>
                                        <div>
                                            <h2>Other assistance finders</h2>
                                            <h3>Savings Finder</h3>
                                            <p>
                                                <a id="savings-finder-link" href="https://assistance.service.nsw.gov.au/savings-finder"
                                                    target="_blank" rel="noreferrer">The Savings Finder</a> helps you find relevant savings and rebates.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="daf-line-spacing" />
                                {this.state.questionAnswers && <QuestionAnswers questionAnswers={this.state.questionAnswers}/>}
                            </div>

                            <div className="feedbackHolder">
                                <ThumbsUpThumbsDown sourceApplication="Disaster Recovery Service" label={'<h1>Feedback</h1>'} />
                            </div>
                        </div>
                    </section>
                </div>

                { !this.state.salesforceStatus &&
                <div className="subscribe-container">
                    <div className="container">
                        <h2>Receive personalised disaster recovery support</h2>
                        <p>Our customer care service provide personalised one on one support during recovery from the impact of a natural disaster.</p>
                        <Link to={{ pathname: '/subscribe', state: { surveyId: this.state.id, isValid: true } }}
                            className="button button--primary">Get support</Link>
                    </div>
                </div>
                }
            </Fragment>

        );
    }
}

export default withRouter(ServiceCategories);
