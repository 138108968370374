import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AutoSuggest from 'react-autosuggest';
import classNames from 'classnames';
import SearchIcon from '../../assets/search-icon';
import getSuburbLocations from '../../apis/SuburbLocatorApi';
import './suburbsuggest.scss';
import { CacheStore } from '../../common';

/*
 * This Component manages it's own state. onChangeCallBack and onSelectCallBack is provided to do extra work such as changing the state of FORMIK forms.
 */
const SuburbSuggest = ({ placeholder, onChangeCallBack, onSelectCallBack, onErrorCallBack, haveEnteredManually }) => {
    const [input, setInput] = useState(() => {
        if (!CacheStore.parsedCache || haveEnteredManually) {
            return '';
        }
        const suburb = CacheStore.getUsersAnswersByStep(5).find(i => i.includes('suburb'));
        const postcode = CacheStore.getUsersAnswersByStep(5).find(i => i.includes('postCode'));
        return suburb && postcode ? `${suburb.replace('suburb', '')} ${postcode.replace('postCode', '')}` : '';
    });

    const [suggestions, setSuggestions] = useState([]);
    // const [selected, setSelected] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const [noSuggestionFound, setNoSuggestionFound] = useState(false);

    const fetchRequested = async ({ value }) => {
        if (!value || value.length < 3) {
            setNoSuggestionFound(false);
            setIsOpen(false);
            return;
        }

        try {
            const containsNameAndPostcode = /^[a-z- ]* [0-9]{0,4}$/i.test(value);

            if (containsNameAndPostcode) {
                value = value.replace(/[0-9]/g, '');
            }

            setIsOpen(true);
            const suburbs = await getSuburbLocations(value.trim());

            if (!suburbs) {
                onErrorCallBack();
                return;
            } else if (suburbs.length === 0) {
                setNoSuggestionFound(true);
                setSuggestions([]);
                return;
            }

            setNoSuggestionFound(false);
            setSuggestions(suburbs.length > 10 ? suburbs.slice(0, 10) : suburbs);
        } catch (e) {
            setSuggestions([]);
            onErrorCallBack();
        }
    };

    const onSuggestionsClearRequestedHandler = () => {
        setSuggestions([]);
        setNoSuggestionFound(false);
        setIsOpen(false);
    };

    const getSuggestionValueHandler = suggestion => `${suggestion.name} ${suggestion.postcode}`;
    const onSuggestionSelectedHandler = (event, { suggestion }) => {
        // setSelected(suggestion);
        onSelectCallBack(suggestion);
    };

    return (
        <div>
            <div className='form--global-search-group form__input-group notranslate'>
                <div className='form__item has-autocomplete'>
                    <AutoSuggest
                        inputProps={{
                            placeholder: placeholder,
                            value: input,
                            onChange: (event, { newValue }) => {
                                setInput(newValue);
                                onChangeCallBack(newValue);
                            }
                        }}

                        suggestions={suggestions}
                        onSuggestionsFetchRequested={fetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequestedHandler}
                        getSuggestionValue={getSuggestionValueHandler}
                        onSuggestionSelected={onSuggestionSelectedHandler}
                        renderSuggestion={getSuggestionValueHandler}
                    />
                </div>

                <div className='form__actions'>
                    <button className="button button--primary" type='button' style={{ backgroundColor: '#2e5299', border: '2px solid #2e5299' }} >
                        <SearchIcon/>
                    </button>
                </div>
            </div>
            <div className={classNames('message-line', { error: noSuggestionFound })}>
                {noSuggestionFound
                    ? 'Unfortunately we were unable to find your postcode. Please try again or enter your postcode manually.'
                    : isOpen
                        ? 'searching...'
                        : 'Start typing and results will appear'}
            </div>
        </div>

    );
};

SuburbSuggest.propTypes = {
    placeholder: PropTypes.string,
    onChangeCallBack: PropTypes.func,
    onSelectCallBack: PropTypes.func,
    onErrorCallBack: PropTypes.func,
    haveEnteredManually: PropTypes.bool
};

SuburbSuggest.defaultProps = {
    placeholder: 'search',
    onChangeCallBack: Function.prototype,
    onSelectCallBack: Function.prototype,
    onErrorCallBack: Function.prototype,
    haveEnteredManually: false
};

export default SuburbSuggest;
