import React, { Component } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ShareSummaryResultsWithoutRecaptcha extends Component {
    validateEmail = (email) => {
        // TODO: get rid of this warning
        // 8:20  warning  Unsafe Regular Expression  security/detect-unsafe-regex
        // eslint-disable-next-line security/detect-unsafe-regex
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    validate = (values) => {
        let errors = {};
        let hasError = false;

        if (!this.validateEmail(values.emailAddress.trim())) {
            errors.emailAddress = 'Please enter a valid email address';
            hasError = true;
        }

        if (!hasError) {
            errors = {};
        }
        return errors;
    };

    render () {
        return (
            <>
                <Formik
                    validate={this.validate}
                    initialValues={
                        {
                            emailAddress: ''
                        }
                    }
                    onSubmit={this.props.shareResultsHandler}>
                    {({
                        values,
                        isSubmitting,
                        handleChange
                    }) => (
                        <Form>
                            <div className="page-sub-section send-email covid-summary-signup">
                                <h3>No time now? Save your list for later.</h3>
                                We’ll email you a link to your results.

                                <div className="form__input-group">
                                    <div className="form__item">
                                        <label htmlFor="emailAddress">Email Address</label>
                                        <input type="text" id="emailAddress" onChange={handleChange}
                                            value={values.emailAddress}/>
                                    </div>
                                    <div className="form__actions">
                                        <button className="button button--tertiary" type="submit"
                                            disabled={isSubmitting}>
                                            <span className="hide-btn-text">Email results</span>
                                        </button>
                                    </div>
                                </div>
                                <ErrorMessage className="form__error" name={'emailAddress'}
                                    component={'div'}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

ShareSummaryResultsWithoutRecaptcha.propTypes = {
    shareResultsHandler: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(ShareSummaryResultsWithoutRecaptcha);
