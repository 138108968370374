import addressApi from './addressApi';

let currentRequestNo = 0;
const addressFetchOrderedApi = {
    getLatestSuggestions: async (value) => {
        const { requestNo, suggestions } = await addressApi.getSuggestions(value, ++currentRequestNo);
        return {
            suggestions,
            isLatest: requestNo === currentRequestNo
        };
    }
};

export default addressFetchOrderedApi;
