import Logo from '@servicensw/header/src/images/servicensw-logo.png';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ title }) => {
    return (
        <header className="page-header">
            <div className="container">
                <div className="print-logo">
                    <img src={Logo} alt="Service NSW"/>
                </div>
                <h1>{title}</h1>
            </div>
        </header>
    );
};

Header.propTypes = {
    title: PropTypes.string
};

export default Header;
