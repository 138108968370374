import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { FormCheckboxGroup } from '@snsw/react-component-library';
import './_exclusive-checkbox-group.scss';

// Component library doesn't offer an exclusive checkbox group,
// or the ability to set the individual items checked value.
// They simply need to pass the value along in the group component.
// TODO: Update this when it becomes available.
export default class ExclusiveCheckboxGroup extends Component {
    static displayName = 'ExclusiveCheckboxGroup';

    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.object).isRequired,
        formik: PropTypes.object.isRequired,
        onChange: PropTypes.func
    };

    static defaultProps = {
        onChange: Function.prototype
    };

    constructor(props) {
        super(props);

        this.state = this.getExclusiveItem();
        this.ref = createRef();
        this.state = { ...this.state, ...this.props.formik.initialValues };
    }

    getExclusiveItem() {
        const exclusiveItem = this.props.options.find(option => option.exclusive);

        return {
            exclusiveItem,
            checked: exclusiveItem && exclusiveItem.checked
        };
    }

    getNonExclusiveDomItems() {
        const parent = this.ref.current.parentNode.parentNode;

        return Array
            .from(parent.querySelectorAll('[class*="Input-sc-"]'))
            .filter(item => item.value !== this.state.exclusiveItem.value)
        ;
    }

    syncValuesWithElements() {
        if (this.state.exclusiveItem.isChecked) {
            this.getNonExclusiveDomItems().forEach(item => {
                item.disabled = true;
            });
        }
    }

    componentDidMount() {
        this.syncValuesWithElements();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.options, prevProps.options)) {
            this.setState(...[this.getExclusiveItem()]);
        }
    }

    onCheckBoxGroupChange = ({ handleChange, setFieldValue, initialValues }) => event => {
        /* Stores answer in both this.state and formik so form can remember checked options
           when unchecking exclusive and when editing answer from summary page. */
        const { checked, value } = event.target;
        const { onChange } = this.props;

        if (value === this.state.exclusiveItem.value) {
            if (checked) {
                Object.keys(initialValues).forEach(key => {
                    setFieldValue(key, key === value);
                });
            } else {
                setFieldValue(value, checked);
            }

            this.getNonExclusiveDomItems().forEach(item => {
                if (!checked) {
                    item.removeAttribute('disabled');
                    setFieldValue(item.value, this.state[`${item.value}`]);
                } else {
                    item.disabled = checked;
                }
            });
        } else {
            this.setState({ ...this.state, [`${value}`]: checked });
            setFieldValue(value, checked);
        }

        handleChange(event);
        onChange(event);

        if (checked !== this.state.checked) {
            this.setState({ checked });
        }
    };

    render() {
        const props = { ...this.props };
        const { formik } = props;
        const { handleChange, setFieldValue, initialValues } = formik;

        delete props.onChange;
        delete props.formik;

        return (
            <FormCheckboxGroup
                inputRef={this.ref}
                {...props}
                onChange={this.onCheckBoxGroupChange({ handleChange, setFieldValue, initialValues })}
            />
        );
    }
}
