import React from 'react';
import GenericError from '../../../pages/GenericError';

const NotFoundPage = () => (
    <GenericError
        link="/"
        pageTitle="Disaster Assistance Finder"
        title="Page not found."
        message="The page you are trying to find doesn't exist. Please try with correct url."
    />
);

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
