import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SurveyForm from '../survey-form/SurveyForm';
import ServiceCategories from '../survey-form/service-categories/ServiceCategories';
import CafErrorPage from '../../components/covid-survey-form/ErrorPage';
import CafNotFoundPage from '../../components/covid-survey-form/NotFoundPage';
import DafErrorPage from '../../components/survey-form/ErrorPage';
import DafNotFoundPage from '../../components/survey-form/NotFoundPage';
import TransactionReplayForm from '../transaction-replay-form/TransactionReplayForm';
import PersonalDetailsForm from '../survey-form/personal-details-form/PersonalDetailsForm';
import CovidPersonalDetailsForm from '../covid-survey-form/covid-personal-details-form/CovidPersonalDetailsForm';
import SupportSummary from '../covid-survey-form/support-summary/SupportSummary';
import Unsubscribe from '../unsubscribe/Unsubscribe';
import CafUnavailablePage from '../covid-survey-form/CafUnavailablePage/CafUnavailablePage';
import '@servicensw/grid/dist/grid.css';
import '@servicensw/header/dist/header.css';
import '@servicensw/buttons/index.scss';
import '@servicensw/form/index.scss';
import './App.scss';

function App () {
    return (
        <div className="App">
            <BrowserRouter basename={ process.env.PUBLIC_URL }>
                <Switch>
                    <Route exact path={'/'} component={SurveyForm} />
                    <Route path={'/summary/:id/:email?'} component={ServiceCategories} />
                    <Route exact path={'/saveTransactionLogs/:fromId/:toId'} component={TransactionReplayForm} />
                    <Route path={'/subscribe'} component={PersonalDetailsForm} />
                    <Route path={'/:disasterType/subscribe'} component={CovidPersonalDetailsForm} />
                    <Route path={'/:disasterType/summary/:id'} component={SupportSummary} />
                    <Route exact path={'/covid'} component={CafUnavailablePage} />
                    <Route path={'/:disasterType/unsubscribe/:id'} component={Unsubscribe} />
                    <Route path={'/error'} component={DafErrorPage} />
                    <Route exact path={'/covid-error'} component={CafErrorPage} />
                    <Route exact path={'/covid/:placeholder'} component={CafNotFoundPage} />
                    <Route component={DafNotFoundPage} />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

App.displayName = 'App';

export default App;
