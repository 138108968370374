import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import './ThumbsUpThumbsDown.scss';

// TODO: this should probably be turned into a HOC
export default class ThumbsUpThumbsDown extends Component {
    static displayName = 'ThumbsUpThumbsDown';

    static propTypes = {
        sourceApplication: PropTypes.string.isRequired,
        label: PropTypes.string
    };

    static defaultProps = {
        label: ''
    };

    static PLACEHOLDER_ID = 'quickFeedHolder';
    static SCRIPT_LOADER_ID = 'quickfeed-script-loader';

    async componentDidMount () {
        const { initQuickFeed } = this;
        const existingScriptLoader = document.getElementById(ThumbsUpThumbsDown.SCRIPT_LOADER_ID);
        const existingPlaceHolder = document.getElementById(ThumbsUpThumbsDown.PLACEHOLDER_ID);
        if (!existingPlaceHolder) {
            ThumbsUpThumbsDown.appendElement('div', { id: ThumbsUpThumbsDown.PLACEHOLDER_ID });
        }
        if (!existingScriptLoader) {
            const scriptProps = {
                type: 'text/javascript',
                id: ThumbsUpThumbsDown.SCRIPT_LOADER_ID
            };
            try {
                // The quickfeed script is located at https not http
                // The script also tries to load a css file, using the current window protocol,
                // but when testing locally, (http), it 404's because the url doesn't exist.
                // Force HTTPS on all quickfeed urls.
                const response = await fetch(process.env.REACT_APP_TUTD_SCRIPT_URL);
                const text = await response.text();
                const parsed = text
                    .replace('environment?"//onegov', 'environment?"https://onegov')
                    .replace('css":"//uat', 'css":"https://uat');
                ThumbsUpThumbsDown.appendElement('script', scriptProps, parsed);
                initQuickFeed();
            } catch (err) {
                // Fallback to loading normally
                ThumbsUpThumbsDown.appendElement('script', {
                    ...scriptProps,
                    eventListeners: {
                        load: initQuickFeed
                    },
                    src: process.env.REACT_APP_TUTD_SCRIPT_URL
                });
            }
        } else {
            initQuickFeed();
        }
    }

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState) || !isEqual(this.context, nextContext);
    }

    initQuickFeed = () => {
        const { label, sourceApplication } = this.props;

        window.quickFeed.init({
            placeholder: ThumbsUpThumbsDown.PLACEHOLDER_ID,
            label: label,
            environment: process.env.REACT_APP_TUTD_ENV,
            sourceApplication: sourceApplication
        });
    };

    static appendElement (type, props = {}, children = null) {
        const el = document.createElement(type);
        const listeners = Object.keys(props).includes('eventListeners') ? { ...props.eventListeners } : {};

        if (Object.keys(listeners).length) {
            Object.entries(listeners).forEach(([key, value]) => {
                el.addEventListener(key, value, false);
            });
            delete props.eventListeners;
        }

        Object.entries(props).forEach(([key, value]) => {
            el.setAttribute(key, value);
        });

        if (children) {
            el.appendChild(typeof children === 'string' ? document.createTextNode(children) : children);
        }

        document.body.appendChild(el);
    }

    render () {
        /**
         * Note: Returning null here causes a warning:
         *
         * react_devtools_backend.js:2273 ./src/components/thumbs-up-thumbs-down/ThumbsUpThumbsDown.js
         * Line 1:8:  'React' is defined but never used  no-unused-vars
         */
        return <div></div>;
    }
}
