import { answerIdOptionMapping } from '../components/covid-survey-form/support-summary/QuestionAnswers/QuestionAnswerMapper';

export default class CacheStore {
    static parsedCache = null;
    static containsSuburb = false;
    static containsPostcode = false;

    static userAnswersMap = { 1: [], 2: [], 3: [], 4: [], 5: [] };

    static setCache = data => {
        CacheStore.clear();
        CacheStore.parsedCache = data;
        const allOptions = data.optionIDs;

        for (const option of allOptions) {
            if (option.includes('suburb')) {
                CacheStore.userAnswersMap[5].push(option);
                CacheStore.containsSuburb = true;
                continue;
            } else if (option.includes('postCode')) {
                CacheStore.userAnswersMap[5].push(option);
                CacheStore.containsPostcode = true;
                continue;
            }

            const step = answerIdOptionMapping[`${option}`].step;
            CacheStore.userAnswersMap[Number(step)].push(option);
        }
    };

    static getUsersAnswersByStep = step => CacheStore.userAnswersMap[Number(step)];

    static updateLocation = (suburb, postcode) => {
        CacheStore.userAnswersMap[5] = [...CacheStore.getUsersAnswersByStep(5).filter(i => !i.includes('suburb') && !i.includes('postCode')), postcode, suburb];
        // updating the store state
        if (suburb) {
            CacheStore.containsSuburb = true;
        }
        if (postcode) {
            CacheStore.containsPostcode = true;
        }
    };

    static clear = () => {
        CacheStore.parsedCache = null;
        CacheStore.containsSuburb = false;
        CacheStore.containsPostcode = false;
        CacheStore.userAnswersMap = { 1: [], 2: [], 3: [], 4: [], 5: [] };
    };
}
