const headerWithKey = { 'X-API-KEY': process.env.REACT_APP_APIGEE_CLIENT_ID };

export const SurveyFormApi = {
    saveSurveyData: (disasterType, data) => {
        return new Promise((resolve, reject) => {
            data.disasterType = disasterType;
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/${disasterType}/surveyform`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey },
                body: JSON.stringify(data) // we need to do another stringify to escape quotes as we are sending the json value as a string
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    getServices: (id, email) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/summary?surveyFormId=${id}&email=${email}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey }
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    savePersonalDetails: (data, id) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/subscribe?surveyFormId=${id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey },
                body: JSON.stringify(data) // we need to do another stringify to escape quotes as we are sending the json value as a string
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.json());
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    },

    sendServicesOfferingEmail: (id, email) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_BASE_PATH}/summary/email?surveyFormId=${id}&emailAddress=${email}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Accept: 'application/json', ...headerWithKey }
            })
                .then(response => {
                    if (response.ok) {
                        resolve(response.status);
                    } else {
                        reject(response.statusText);
                    }
                })
                .catch(error => {
                    reject(error.message);
                });
        });
    }
};
