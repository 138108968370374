import React, { useEffect, useState, memo } from 'react';
import {
    AccordionBody,
    AccordionHeader,
    AccordionSubTitle,
    AccordionTileContainer,
    TileBody,
    ToggleIcon
} from './Accordion.styled';
import chevrondown from '../../../assets/icons/chevrondown.svg';
import PropTypes from 'prop-types';

export const AccordionTile = ({ expanded, title, children }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(expanded);
    }, [expanded]);

    const toggleBodyVisibility = () => {
        setOpen(!open);
    };

    const onKeyDown = e => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            toggleBodyVisibility();
        }
    };

    const getTileBody = () => {
        if (!open) {
            return null;
        }
        return (
            <TileBody>
                {open && <AccordionBody>{children}</AccordionBody>}
            </TileBody>
        );
    };

    return (
        <AccordionTileContainer data-testid="tile-container" tabIndex="0" open={open} onClick={toggleBodyVisibility} onKeyDown={onKeyDown}>
            <div className="progress">
                <div className="top">
                </div>
                <div className="bottom">
                </div>
            </div>
            <AccordionHeader open = {open} onClick={toggleBodyVisibility} onKeyDown={onKeyDown}
            >
                <AccordionSubTitle>
                    {title}
                </AccordionSubTitle>
                <ToggleIcon rotateIt={open}>
                    <img src={chevrondown} alt="chevrondown" />
                </ToggleIcon>
            </AccordionHeader>
            { getTileBody() }
        </AccordionTileContainer>
    );
};

AccordionTile.propTypes = {
    title: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    children: PropTypes.node
};

AccordionTile.defaultProps = {
    expanded: false,
    children: null
};

export default memo(AccordionTile);
