import React, { memo } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { pluralise, pluraliseReversed } from '../../../common';
import chevron from '../../../assets/icons/chevron.svg';
import './Category.scss';

const renderNewServices = memoizeOne((noOfNewServices) => {
    if (noOfNewServices > 0) {
        return (
            <span className="blue-pill category-content__new-services">
                {`${noOfNewServices} new`}
            </span>
        );
    }

    return null;
});

const renderExpiringServices = memoizeOne((noOfExpiringServices) => {
    if (noOfExpiringServices > 0) {
        return (
            <span className="category-content__service-ends-soon">
                {`${noOfExpiringServices} ${pluraliseReversed('end', noOfExpiringServices)} soon`}
            </span>
        );
    }

    return null;
});

const Category = (props) => {
    const { category: parentCategory } = props;
    const { category, noOfExpiringServices, noOfNewServices, services } = parentCategory;
    const { length } = services;

    return (
        <div className="category">
            <div className="category__left-side">
                <div className="category-content">
                    <h2 className="category-content__title">{category}</h2>
                    {renderNewServices(noOfNewServices)}
                    {renderExpiringServices(noOfExpiringServices)}
                </div>
            </div>
            <div className="category__right-side">
                <div className="category__total">{`${length} ${pluralise('item', length)}`}</div>
                <div className="category__arrow"><img src={chevron} alt="chevron" /></div>
            </div>
        </div>
    );
};

Category.displayName = 'Category';

Category.propTypes = {
    category: PropTypes.shape({
        category: PropTypes.string.isRequired,
        services: PropTypes.array.isRequired,
        noOfExpiringServices: PropTypes.number,
        noOfNewServices: PropTypes.number
    })
};

Category.defaultProps = {
    category: void 0
};

export default memo(Category);
