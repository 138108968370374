
export const validatePhone = (phone) => {
    const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/;
    return re.test(String(phone).toLowerCase());
};

export const validateEmail = (email) => {
    // TODO: get rid of this warning
    // 145:20  warning  Unsafe Regular Expression  security/detect-unsafe-regex
    // eslint-disable-next-line security/detect-unsafe-regex
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const onAddressHasBecomeValid = (updatedAddress, setFieldValue) => {
    setFieldValue('residentialAddress', updatedAddress);
};

export const onAddressHasBecomeInvalid = () => {

};
