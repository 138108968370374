import React from 'react';
import GenericError from '../../../pages/GenericError';

const ErrorPage = () => (
    <GenericError
        link="/covid"
        pageTitle="Covid Assistance Finder"
        title="Something's not right."
        message="It seems we’re facing some technical issues. You can try again or meet with one of our specialists for a free appointment and we’ll help you through the process."
    />
);

ErrorPage.displayName = 'ErrorPage';

export default ErrorPage;
