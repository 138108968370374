import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Notice from '../../../Notice';
import Header from '../../../header/Header';
import '../styles/_generic-error.scss';

const GenericError = ({ className, link, linkText, message, type, pageTitle, title }) => (
    <div className={classNames('generic-error', className)}>
        <Header title={pageTitle} />
        <div className="container">
            <Notice type={type} title={title} message={message} />
            <div className="generic-error__link">
                <Link to={link}>{linkText}</Link>
            </div>
        </div>
    </div>
);

GenericError.displayName = 'GenericErrorPage';

GenericError.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string,
    link: PropTypes.string,
    className: PropTypes.string,
    linkText: PropTypes.string
};

GenericError.defaultProps = {
    type: 'error',
    link: '/',
    linkText: 'Return to Home Page',
    className: null
};

export default memo(GenericError);
