export const allSteps = {
    1: 'Household',
    2: 'About You',
    3: 'Study, skills and employment',
    4: 'Business Impact',
    5: 'Crisis Impact'
};

const allQuestions = {
    1: { title: 'What is your current housing situation?', step: 1 },
    2: { title: 'Do you have children or dependents?', step: 1 },
    3: { title: 'How old are you?', step: 2 },
    4: { title: 'What is your resident status?', step: 2 },
    5: { title: 'Do you identify as Aboriginal or Torres Strait Islander?', step: 2 },
    6: { title: 'Are you a student, trainee or apprentice?', step: 3 },
    7: { title: 'What is your employment status?', step: 3 },
    8: { title: 'Are you looking for work or need support to change careers?', step: 3 },
    9: { title: 'Which situation describes you best?', step: 3 },
    10: { title: 'Are you looking to do further study or training?', step: 3 },
    11: { title: 'Which option applies to you?', step: 3 },
    12: { title: 'Are you a business owner?', step: 4 },
    13: { title: 'How many employees do you currently have?', step: 4 },
    14: { title: 'How does your current revenue compare to a similar period last year?', step: 4 },
    15: { title: 'What support matter the most to you right now?', step: 4 },
    16: { title: 'Do any of the below circumstances apply to you?', step: 5 },
    17: { title: 'What support matters most to you?', step: 5 },
    18: { title: 'What is your location?', step: 5 }
};

const allOptions = {
    1: 'Yes',
    2: 'No',
    3: 'Renter',
    4: 'Owner - with mortgage',
    5: 'Owner - without mortgage',
    6: 'Living with family or friends - few expenses',
    7: 'Other',
    8: 'Under 16',
    9: '16 – 21',
    10: '22 – 39',
    11: '40 – 66',
    12: '67 – 80',
    13: 'Over 80',
    14: 'Australian citizen, permanent residence visa holder or protected Special Category visa (SCV) holder',
    15: 'New Zealand citizen living in Australia (SCV holder)',
    16: 'International citizen living in Australia (non New Zealand)',
    17: 'Student',
    18: 'Trainee',
    19: 'Apprentice',
    20: 'None of the above',
    21: 'Full-time employee',
    22: 'Part-time employee',
    23: 'Long-term casual employee',
    24: 'Reduced hours/income due to COVID-19',
    25: 'Recently stood down',
    26: 'Not currently employed',
    27: 'Retired or not looking for work',
    28: 'None of the above',
    29: 'I have a career in mind but need advice on options to reach my goals or would like to hear more about a specific industry',
    30: 'I\'m unsure what my future career or job is and need help figuring out what job suits my skills and experience',
    31: 'I know what type of study or training I\'m looking for',
    32: 'I\'d like help figuring out what study or training options would be best for me',
    33: 'I\'m a sole trader',
    34: '1 to 19',
    35: '20 to 49',
    36: '50 to 249',
    37: '250 or More',
    38: '0-14% reduction',
    39: '15-29% reduction',
    40: '30-49% reduction',
    41: '50% or more reduction',
    42: 'No reduction at the moment',
    43: 'Financial support',
    44: 'Operational advice including managing employees',
    45: 'Advice through changing financial conditions',
    46: 'Resources and tools for a changed workplace environment',
    47: 'Other',
    48: 'In self-isolation or self-quarantine due to health concerns',
    49: 'In self-isolation or self-quarantine due to travel',
    50: 'National Disability Insurance Scheme (NDIS) participant',
    51: 'Healthcare worker',
    52: 'None of the above',
    53: 'Financial support',
    54: 'Food, household and living',
    55: 'Child support',
    56: 'Mental health',
    57: 'Study, skills and employment',
    58: 'Business staff support',
    59: 'Business cost support',
    60: 'Workplace tools and resources'
};

export const answerIdOptionMapping = {
    isPrimaryCarer: { question: 2, optionSelected: 1, step: 1 },
    notPrimaryCarer: { question: 2, optionSelected: 2, step: 1 },
    housingSituationrenter: { question: 1, optionSelected: 3, step: 1 },
    housingSituationownerWithMortgage: { question: 1, optionSelected: 4, step: 1 },
    housingSituationownerWithoutMortgage: { question: 1, optionSelected: 5, step: 1 },
    housingSituationlivingWithFamilyOrFriendsWithoutExpense: { question: 1, optionSelected: 6, step: 1 },
    housingSituationother: { question: 1, optionSelected: 7, step: 1 },
    isstudent: { question: 6, optionSelected: 17, step: 3 },
    istrainee: { question: 6, optionSelected: 18, step: 3 },
    isapprentice: { question: 6, optionSelected: 19, step: 3 },
    isnotStudent: { question: 6, optionSelected: 20, step: 3 },
    employmentStatusfullTime: { question: 7, optionSelected: 21, step: 3 },
    employmentStatuspartTime: { question: 7, optionSelected: 22, step: 3 },
    employmentStatuslongTermCasual: { question: 7, optionSelected: 23, step: 3 },
    employmentStatusreducedHours: { question: 7, optionSelected: 24, step: 3 },
    employmentStatusrecentlyStoodDown: { question: 7, optionSelected: 25, step: 3 },
    employmentStatuslookingForWork: { question: 7, optionSelected: 26, step: 3 },
    employmentStatusretiredOrNotLookingForWork: { question: 7, optionSelected: 27, step: 3 },
    employmentStatusnone: { question: 7, optionSelected: 28, step: 3 },
    lookingForWorkConditionaltrue: { question: 8, optionSelected: 1, step: 3 },
    lookingForWorkConditionalfalse: { question: 8, optionSelected: 2, step: 3 },
    furtherStudyConditionaltrue: { question: 10, optionSelected: 1, step: 3 },
    furtherStudyConditionalfalse: { question: 10, optionSelected: 2, step: 3 },
    haveSkillsAndKnowsAboutJob: { question: 9, optionSelected: 29, step: 3 },
    haveSkillsButDontKnowAboutJob: { question: 9, optionSelected: 30, step: 3 },
    dontHaveSkillsButKnowsAboutJob: { question: 11, optionSelected: 31, step: 3 },
    dontHaveSkillAndDontKnowAboutJob: { question: 11, optionSelected: 32, step: 3 },
    ageBracketUnder16: { question: 3, optionSelected: 8, step: 2 },
    ageBracket16to21: { question: 3, optionSelected: 9, step: 2 },
    ageBracket22to39: { question: 3, optionSelected: 10, step: 2 },
    ageBracket40to66: { question: 3, optionSelected: 11, step: 2 },
    ageBracket67to80: { question: 3, optionSelected: 12, step: 2 },
    ageBracketOver80: { question: 3, optionSelected: 13, step: 2 },
    residentTypeAustralian: { question: 4, optionSelected: 14, step: 2 },
    residentTypeNewZealand: { question: 4, optionSelected: 15, step: 2 },
    residentTypeInternational: { question: 4, optionSelected: 16, step: 2 },
    doesIdentifyAsIndigenousAustralian: { question: 5, optionSelected: 1, step: 2 },
    doesNotIdentifyAsIndigenousAustralian: { question: 5, optionSelected: 2, step: 2 },
    isBusinessOwner: { question: 12, optionSelected: 1, step: 4 },
    notBusinessOwner: { question: 12, optionSelected: 2, step: 4 },
    numEmployeesMyselfOnly: { question: 13, optionSelected: 33, step: 4 },
    numEmployees1to19: { question: 13, optionSelected: 34, step: 4 },
    numEmployees20to49: { question: 13, optionSelected: 35, step: 4 },
    numEmployees50to249: { question: 13, optionSelected: 36, step: 4 },
    numEmployees250OrMore: { question: 13, optionSelected: 37, step: 4 },
    revenueReduction0to14Percent: { question: 14, optionSelected: 38, step: 4 },
    revenueReduction15to29Percent: { question: 14, optionSelected: 39, step: 4 },
    revenueReduction30to49Percent: { question: 14, optionSelected: 40, step: 4 },
    revenueReduction50orMorePercent: { question: 14, optionSelected: 41, step: 4 },
    revenueReductionNone: { question: 14, optionSelected: 42, step: 4 },
    businessSupportFinancial: { question: 15, optionSelected: 43, step: 4 },
    businessSupportOperationalAdvice: { question: 15, optionSelected: 44, step: 4 },
    businessSupportFinancialAdvice: { question: 15, optionSelected: 45, step: 4 },
    businessSupportResources: { question: 15, optionSelected: 46, step: 4 },
    businessSupportOther: { question: 15, optionSelected: 47, step: 4 },
    selfIsolatingReasonexistingHealthConcerns: { question: 16, optionSelected: 48, step: 5 },
    selfIsolatingReasonreturnFromTravel: { question: 16, optionSelected: 49, step: 5 },
    selfIsolatingReasonndisParticipant: { question: 16, optionSelected: 50, step: 5 },
    selfIsolatingReasonhealthcareWorker: { question: 16, optionSelected: 51, step: 5 },
    selfIsolatingReasonnoneOfTheAbove: { question: 16, optionSelected: 28, step: 5 },
    'supportOptionsFinancial support': { question: 17, optionSelected: 53, step: 5 },
    'supportOptionsFood, household and living': { question: 17, optionSelected: 54, step: 5 },
    'supportOptionsChild support': { question: 17, optionSelected: 55, step: 5 },
    'supportOptionsMental health': { question: 17, optionSelected: 56, step: 5 },
    'supportOptionsSkills and employment': { question: 17, optionSelected: 57, step: 5 },
    'supportOptionsBusiness staff support': { question: 17, optionSelected: 58, step: 5 },
    'supportOptionsBusiness costs support': { question: 17, optionSelected: 59, step: 5 },
    'supportOptionsWorkplace tools and resources': { question: 17, optionSelected: 60, step: 5 }
};

export const getAllQuestions = () => {
    const quetionsList = [];
    for (let i = 1; i <= 18; i++) {
        quetionsList.push({ id: i, question: allQuestions[`${i}`].title, selectedOptions: [], step: allQuestions[`${i}`].step, selectedOptionIds: [], visible: true });
    }
    return quetionsList;
};

const findQuestion = (questionsList, isLocationAnswer, container) => questionsList.find(question => isLocationAnswer ? question.id === 18 : question.id === container.question);

export const populateAnswers = (answer, questionsList) => {
    const container = answerIdOptionMapping[`${answer}`];

    const isLocationAnswer = answer.includes('suburb') || answer.includes('postCode');

    if (!container && !isLocationAnswer) {
        return;
    }

    const foundQuestion = findQuestion(questionsList, isLocationAnswer, container);

    if (answer.includes('suburb')) {
        foundQuestion.selectedOptions.push(answer.replace('suburb', ''));
    } else if (answer.includes('postCode')) {
        foundQuestion.selectedOptions.push(answer.replace('postCode', ''));
    } else {
        foundQuestion.selectedOptions.push(allOptions[container.optionSelected]);
    }
    foundQuestion.selectedOptionIds.push(answer);
};
