const basePath = process.env.REACT_APP_API_BASE_PATH;
const headerWithKey = { 'X-API-KEY': process.env.REACT_APP_APIGEE_CLIENT_ID };

const api = {
    getSecure: async (url) => {
        return api.request(url, {
            method: 'GET',
            headers: { ...headerWithKey }
        });
    },
    postSecure: async (url, body = {}, headers = {}) => {
        return api.request(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json', ...headers, ...headerWithKey }
        });
    },
    putSecure: async (url, body = {}) => {
        const response = await fetch(basePath + url, Object.assign({
            credentials: 'include'
        }, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json', ...headerWithKey }
        }));

        if (response.status !== 200) {
            throw Error('Received a non 200 response');
        }
        let text;
        try {
            text = await response.text();
            return JSON.parse(text);
        } catch (_) {
            return text;
        }
    },
    patchSecure: async (url, body = {}) => {
        const response = await fetch(basePath + url, Object.assign({
            credentials: 'include'
        }, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json', ...headerWithKey }
        }));

        if (response.status !== 200) {
            throw Error('Received a non 200 response');
        }
        let text;
        try {
            text = await response.text();
            return JSON.parse(text);
        } catch (_) {
            return text;
        }
    },
    request: async (url, options) => {
        const response = await fetch(basePath + url, Object.assign({
            credentials: 'include'
        }, options));

        if (response.status !== 200) {
            throw Error('Received a non 200 response');
        }

        return response.json();
    },
    getQueryParameters: (data) => {
        const queryString = Object.entries(data)
            .filter(([, val]) => !!val)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&');
        return queryString ? `?${queryString}` : '';
    }
};

export default api;
