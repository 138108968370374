import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_footer-nav.scss';

const FooterNav = ({ onBackPressed, forwardLabel, backLabel, className, forwardLabelDisabled }) => (
    <div className={classNames('form__actions btn-section footer-nav', className)}>
        <input type="submit" className="button button--large button--primary flex-right" value={forwardLabel} disabled={forwardLabelDisabled} />
        <input type="button" className="button button--large button--secondary flex-left" value={backLabel} onClick={onBackPressed} />
    </div>
);

FooterNav.displayName = 'FooterNav';

FooterNav.propTypes = {
    forwardLabel: PropTypes.string,
    backLabel: PropTypes.string,
    onBackPressed: PropTypes.func,
    className: PropTypes.string,
    forwardLabelDisabled: PropTypes.bool
};

FooterNav.defaultProps = {
    forwardLabel: 'Next',
    backLabel: 'Back',
    onBackPressed: Function.prototype,
    className: null,
    forwardLabelDisabled: false
};

export default memo(FooterNav);
