import api from './api';

export default async function getSuburbLocations (value) {
    try {
        const { locations } = await api.getSecure(`/suburb?value=${encodeURIComponent(value)}`);
        return locations;
    } catch (error) {
        console.error('NSW location service is not available at the moment.', error);
        return null;
    }
}
