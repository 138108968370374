import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import './OneQuestionAnswer.scss';
import { smoothScrollTo } from '../../../../common';

const OneQuestionAnswer = ({ step, question, selectedOptions, editAnswers }) => {
    const params = useParams();
    const history = useHistory();

    const handleEditClick = () => {
        history.push(`/${params.disasterType}#step${step}`);
        smoothScrollTo();
    };
    return (
        <div className="one-question-answer">
            <div className="question-text">
                {question}
            </div>
            <div className="answer-container">
                <div className="answers-container">
                    {selectedOptions.length ? selectedOptions.map(option => <li key={option}>{option}</li>) : 'No answer'}
                </div>
                {!editAnswers &&
                <button className="link-button" onClick={handleEditClick}>Edit answer</button>}
            </div>
        </div>
    );
};

OneQuestionAnswer.propTypes = {
    step: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    editAnswers: PropTypes.bool.isRequired
};

export default memo(OneQuestionAnswer);
