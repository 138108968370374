import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InPageAlert } from '@snsw/react-component-library';

const Notice = ({ message, title, type, style }) => {
    const modifier = `notice--${type || ''}`;

    return (
        <InPageAlert variant={type} title={title} id={modifier} name={modifier} style={style}>
            {message}
        </InPageAlert>
    );
};

Notice.displayName = 'Notice';

Notice.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    type: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.object
};

Notice.defaultProps = {
    title: null,
    type: null,
    style: null
};

export default memo(Notice);
