import React, { memo } from 'react';
import PropTypes from 'prop-types';
// import get from 'lodash.get'
import Notice from '../Notice';

const buildNoticeFromEnv = (appType) => {
    // const prefix = appType.toUpperCase()
    // const enabled = get(process.env, `REACT_APP_${prefix}_NOTICE_ENABLED`, false)
    // const title = get(process.env, `REACT_APP_${prefix}_NOTICE_TITLE`, null)
    // const text = get(process.env, `REACT_APP_${prefix}_NOTICE_TEXT`, null)
    // const type = get(process.env, `REACT_APP_${prefix}_NOTICE_TYPE`, null)

    // if (enabled) {
    //     return {
    //         title,
    //         text,
    //         type
    //     }
    // }

    return null;
};

const ConfigurableNotice = ({ appType }) => {
    const info = buildNoticeFromEnv(appType);

    if (info) {
        const { title, text, type } = info;

        return <Notice message={text} title={title} type={type} />;
    }

    return null;
};

ConfigurableNotice.displayName = 'ConfigurableNotice';

ConfigurableNotice.propTypes = {
    appType: PropTypes.string.isRequired
};

export default memo(ConfigurableNotice);
