import React from 'react';

const SearchIcon = () => {
    return (
        <svg version="1.1" width="20px" height="21px" viewBox="0 0 31 32" xmlns="http://www.w3.org/2000/svg">
            <title>Search Icon</title>
            <g fill="none">
                <g transform="translate(-14 -10)" fill="#242934">
                    <g transform="translate(14 10)">
                        <path fill="#FFF"
                            d="M12.256 0C5.48 0 0 5.528 0 12.364c0 6.835 5.48 12.363 12.256 12.363 2.419 0 4.658-.716 6.556-1.932l8.854 8.932 3.064-3.09-8.742-8.796a12.347 12.347 0 002.524-7.477C24.512 5.528 19.032 0 12.256 0zm0 2.91c5.19 0 9.372 4.218 9.372 9.454 0 5.235-4.182 9.454-9.372 9.454-5.19 0-9.372-4.219-9.372-9.454 0-5.236 4.182-9.455 9.372-9.455z"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default SearchIcon;
