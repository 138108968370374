import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Parser from 'html-react-parser';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import LocationBlue from './LocationBlue';
import '../../survey-form/service-categories/ServiceCategories.scss';
import './_covid-details.scss';
import phoneNumWithTel from '../../../common/phoneNumWithTel';
import { buildServiceLookUrl } from './SupportSummary';

export default class CovidDetails extends Component {
    static displayName = 'CovidDetails';

    static propTypes = {
        services: PropTypes.array.isRequired,
        categoryName: PropTypes.string.isRequired,
        postcode: PropTypes.any,
        expandedServiceCategoryNames: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        postcode: void 0,
        expandedServiceCategoryNames: []
    };

    constructor (props, context) {
        super(props, context);
        this.state = {};
    }

    onClick = (values) => {
        this.setState({});
    };

    renderEligibilityCriteria = (service) => {
        const { initiativeName } = service;

        const changeText = initiativeName === '2021 COVID-19 Business Grant' ||
            initiativeName === 'JobSaver' ||
            initiativeName === 'COVID-19 Micro business Grant';

        return service.eligibility &&
            <div className="product__eligibility">
                <div style={{ fontWeight: 'bold' }}>
                    { changeText ? 'Eligibility:' : 'Who this is for:'}
                </div>
                {Parser(service.eligibility)}
            </div>;
    };

    getURL (category) {
        let searchQuery = '';
        switch (category) {
        case 'Financial support':
            searchQuery = 'Financial%20support';
            break;
        case 'Food, household and living':
            searchQuery = 'food%20and%20living%20assistance';
            break;
        case 'Child support':
            searchQuery = 'support%20for%20parents';
            break;
        case 'Skills and employment':
            searchQuery = 'skills%20and%20employment%20assistance';
            break;
        case 'Mental health':
            searchQuery = 'Mental%20Health';
            break;
        case 'Business staff support':
            searchQuery = 'employee%20support';
            break;
        case 'Business costs support':
            searchQuery = 'business%20costs%20support';
            break;
        case 'Workplace tools and resources':
            searchQuery = 'workplace%20resources';
            break;
        default:
            searchQuery = 'Financial%20support';
        }

        return buildServiceLookUrl(searchQuery, this.props.postcode);
    }

    renderCallToAction () {
        const { categoryName, expandedServiceCategoryNames } = this.props;

        if (categoryName === 'Skills and employment') {
            return (
                <div className={classNames('grid__container', {
                    invisible: !expandedServiceCategoryNames.includes(categoryName)
                })}>
                    <div className="call-to-action">
                        <div className="call-to-action__title">Get help with your career and training options</div>
                        <div className="call-to-action__text">
                            If you're looking for work, changing career or thinking about further study or training,
                            our contact centre staff can help you understand options available to you.
                        </div>
                        <div className="call-to-action__link call-to-action__new-para">
                            <a
                                href={`${process.env.REACT_APP_SKILLS_AND_EMPLOYMENT_CTA_URL}`}
                                title="book a phone appointment" target="_blank"
                                rel="noopener noreferrer"
                            >
                                book a phone appointment
                            </a>
                        </div>
                    </div>
                </div>
            );
        } else if (categoryName === 'Business costs support') {
            return (
                <div className={classNames('grid__container', {
                    invisible: !expandedServiceCategoryNames.includes(categoryName)
                })}>
                </div>
            );
        }

        return null;
    }

    render () {
        const { services, categoryName, expandedServiceCategoryNames } = this.props;
        const url = this.getURL(categoryName);

        if (services) {
            return (
                <Fragment>
                    <div className={classNames('grid__container', {
                        invisible: !expandedServiceCategoryNames.includes(categoryName)
                    })}>
                        <ul className="service-container">
                            <li className="product-container">
                                <div className="product">
                                    <div className="product__content">
                                        <Grid container spacing={3}>
                                            <Grid item xs={10} sm={7} style={{ display: 'flex' }}>

                                                <div style={{ height: '31px' }}><LocationBlue/></div>

                                                <h3 className="product__initiative_name" style={{ display: 'inline', paddingLeft: '8px' }}>
                                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                                        {`${categoryName} near you`}
                                                    </a>
                                                </h3>
                                            </Grid>

                                        </Grid>

                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                    {this.renderCallToAction()}
                    {
                        services
                            .sort((service1, service2) => service1.serviceRank - service2.serviceRank)
                            .sort(service1 => service1.isNew ? -1 : 1)
                            .sort((service1, service2) => {
                                if (!service1.isNew || !service2.isNew) {
                                    return 0;
                                } else {
                                    return service1.serviceRank - service2.serviceRank;
                                }
                            })
                            .sort(service1 => service1.isExpiring ? -1 : 1)
                            .sort((service1, service2) => {
                                if (!service1.isExpiring || !service2.isExpiring) {
                                    return 0;
                                }
                                if (!(moment(service1.expiryDate).isSame(moment(service2.expiryDate)))) {
                                    return moment(service1.expiryDate).isBefore(moment(service2.expiryDate)) ? -1 : 1;
                                } else {
                                    return service1.serviceRank - service2.serviceRank;
                                }
                            })
                            .map(service => {
                                return (
                                    <div key={`service-item-detail-${service.initiativeName}`} className={classNames('grid__container', {
                                        invisible: !expandedServiceCategoryNames.includes(categoryName)
                                    })}>
                                        <ul className="service-container">
                                            <li className="product-container">
                                                <div className="product">
                                                    <div className="product__content">
                                                        <div>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={7}>

                                                                    <h3 className="product__initiative_name">

                                                                        {service.furtherInformationLink &&
                                                                        <a
                                                                            title={service.initiativeName}
                                                                            href={service.furtherInformationLink}
                                                                            target="_blank" rel="noopener noreferrer">
                                                                            {service.initiativeName}
                                                                        </a>}

                                                                        {!service.furtherInformationLink && <div>{service.initiativeName}</div>}
                                                                    </h3>
                                                                    {service.isNew && <span className="new-item">NEW</span>}
                                                                </Grid>

                                                                {service.formattedExpiryDate && service.formattedExpiryDate !== 0 &&
                                                                    <Grid item xs={12} sm={5}>

                                                                        <div className="product__provider" style={{
                                                                            fontWeight: '500', paddingRight: 'unset'
                                                                        }}>
                                                                            {`Ends ${service.formattedExpiryDate} `}
                                                                        </div>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </div>

                                                        <div className="product__description">
                                                            {Parser(service.description)}
                                                        </div>

                                                        {this.renderEligibilityCriteria(service)}

                                                        {service.furtherInformationLink &&
                                                        <div className="product__footer">
                                                            <div className="product__provider">
                                                                <div style={{ fontWeight: 'bold' }}>{service.provider}</div>
                                                                {service.phoneNumber && phoneNumWithTel(service.phoneNumber)}
                                                            </div>
                                                            <div className="product__further_info">
                                                                <a
                                                                    title="Further information"
                                                                    className="product__further_info_link"
                                                                    href={service.furtherInformationLink}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    Further information
                                                                </a>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                );
                            }) }</Fragment>
            );
        }
        return null;
    }
}
