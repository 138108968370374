import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { AccordionStyled } from './Accordion.styled';
import { AccordionBlue } from './Accordion.blue.styled';

const Accordion = ({ children }) => {
    return (
        <AccordionBlue >
            <AccordionStyled border={ false }>
                { children }
            </AccordionStyled>
        </AccordionBlue>

    );
};

Accordion.defaultProps = {
    onExpandCallBack: Function.prototype,
    onCloseCallBack: Function.prototype
};

Accordion.propTypes = {
    onExpandCallBack: PropTypes.func,
    onCloseCallBack: PropTypes.func,
    children: PropTypes.any
};

export default memo(Accordion);
