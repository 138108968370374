import Parser from 'html-react-parser';

export default function phoneNumWithTel(htmlString) {
    /* The following assumptions are made:
        - only 1 phone number
        - they can be any length
        - they don't have special characters. */
    const digitsWithSpaces = /[0-9 ]+[0-9]+/;
    const match = htmlString.match(digitsWithSpaces);
    htmlString = match && htmlString.replace(match[0], `<a href="tel:${match[0].replaceAll(' ', '')}">${match[0]}</a>`);
    return Parser(htmlString);
}
