import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
// import StepWizardComponent from 'react-step-wizard';
import StepWizardComponent from './ReactStepWizard';
import classNames from 'classnames';
import '../styles/_step-wizard.scss';

const renderSteps = memoizeOne(({ currentStep, totalSteps }) =>
    Array.from(new Array(totalSteps)).map((item, index) => (
        <div
            className={classNames('step-wizard__tab', { 'step-wizard__tab--active': currentStep >= index + 1 })}
            key={`step-${index + 1}`}
        >
        </div>
    )));

const renderNav = memoizeOne(({ currentStep, totalSteps }) => (
    <>
        <div className='step-wizard__title'>{`Step ${currentStep} of ${totalSteps}`}</div>
        <div className={classNames('step-wizard__nav', { 'step-wizard__nav--hidden': currentStep > totalSteps })}>
            {renderSteps({ currentStep, totalSteps })}
        </div>
    </>
));

const StepWizard = ({ children, className, location, transitions, onStepChange }) => {
    const step = location.hash && location.hash.includes('#step') ? parseInt(location.hash.replace('#step', ''), 10) : 1;
    const [currentStep, setCurrentStep] = useState(step);

    const onChange = memoizeOne(({ previousStep, activeStep }) => {
        setCurrentStep(activeStep);
        onStepChange({ previousStep, activeStep });
    });

    return (
        <StepWizardComponent
            className={classNames('step-wizard', className)}
            isHashEnabled
            nav={renderNav({ currentStep, totalSteps: children.length })}
            transitions={transitions}
            onStepChange={onChange}
            initialStep={currentStep}
        >
            {children}
        </StepWizardComponent>
    );
};

StepWizard.displayName = 'StepWizard';

StepWizard.propTypes = {
    children: PropTypes.node.isRequired,
    transitions: PropTypes.shape({
        enterRight: PropTypes.string,
        enterLeft: PropTypes.string,
        exitRight: PropTypes.string,
        exitLeft: PropTypes.string
    }).isRequired,
    className: PropTypes.string,
    onStepChange: PropTypes.func,
    location: PropTypes.object
};

StepWizard.defaultProps = {
    className: null,
    onStepChange: Function.prototype,
    location: {}
};

export default memo(StepWizard);
