import api from './api';

const addressApi = ({
    getSuggestions: async (value, requestNo) => {
        let suggestions = [];
        try {
            suggestions = await api.getSecure(`/NSWPoint/getSuggestions?query=${value}`);
        } catch (error) {
            console.error('NSW Point service is not available at the moment.', error);
        }

        return {
            suggestions: suggestions instanceof Array ? suggestions : [],
            requestNo
        };
    },

    getDetailsById: async (id) => {
        const addressDetails = await api.getSecure(`/NSWPoint/getDetails?id=${id}`);
        return convertAddress(addressDetails);
    }
});

// const convertAddress = ({ data: { coordinates, properties } }) => {
const convertAddress = ({ data: { geo, addressDetails } }) => {
    const {
        streetName,
        streetType,
        siteName,
        localityName,
        stateTerritory,
        postcode,
        addressIdentifier,
        formattedAddress
    } = addressDetails;

    const [longitude, latitude] = geo.geometry.coordinates;

    return {
        unitNumber: getIdentifier(addressDetails, false),
        buildingNumber: getIdentifier(addressDetails, true),
        streetNumber: getStreetNumber(addressDetails),
        streetName,
        streetType,
        propertyName: siteName,
        addressLine: null,
        suburb: localityName,
        state: stateTerritory,
        country: 'AUSTRALIA',
        postcode,
        latitude,
        longitude,
        nswPointId: addressIdentifier,
        formattedAddress: formattedAddress,
        validated: true
    };
};

const getIdentifier = (address, isBuilding) => {
    const { complexUnitType, complexLevelType, complexUnitIdentifier, complexLevelNumber } = address;
    let identifier = '';

    if (complexUnitType === null && complexLevelType === null) {
        return null;
    }

    if (isBuilding && complexUnitType !== 'BLDG') {
        return null;
    }

    if (!isBuilding && complexUnitType === 'BLDG') {
        return null;
    }

    if (complexUnitType !== null) {
        identifier += `${complexUnitType} ${complexUnitIdentifier}`;
    }
    if (complexLevelType !== null) {
        identifier += ` ${complexLevelType} ${complexLevelNumber}`;
    }

    return identifier.trim();
};

const getStreetNumber = (address) => {
    const { streetNumber1, streetNumber2 } = address;
    if (streetNumber1 === null && streetNumber2 === null) {
        return null;
    }

    if (streetNumber1 !== null && streetNumber2 !== null) {
        return `${streetNumber1}-${streetNumber2}`;
    }
    return streetNumber1 || streetNumber2;
};

export default addressApi;
