import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { FormRadioGroup } from '@snsw/react-component-library';
import ExclusiveCheckboxGroup from '../../ExclusiveCheckboxGroup';
import initStepQnA from '../InitialValues/stepQnA';
import FooterNav from '../../FooterNav';
import { collectAnswersFromOptions, yesNoOptions } from '../../../common';
import './PropertyForm.scss';

const initialDamagedVehiclesValues = {
    registeredVehicle: false,
    registeredVessel: false,
    noVehicle: false
};

const initialValues = {
    damagedProperty: false,
    ...initialDamagedVehiclesValues
};

const damagedVehicleOptions = [
    {
        label: 'Vehicle/s registered in NSW',
        value: 'registeredVehicle'
    },
    {
        label: 'Vessel/s registered in NSW',
        value: 'registeredVessel'
    },
    {
        label: 'None of the above',
        value: 'noVehicle',
        exclusive: true
    }
];

export default class PropertyForm extends Component {
    static displayName = 'PropertyForm';

    static propTypes = {
        previousStep: PropTypes.func,
        nextStep: PropTypes.func,
        nextAction: PropTypes.func,
        isActive: PropTypes.bool,
        currentStep: PropTypes.number
    };

    static defaultProps = {
        previousStep: Function.prototype,
        nextStep: Function.prototype,
        nextAction: Function.prototype,
        isActive: false,
        currentStep: 1
    };

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState) || !isEqual(this.context, nextContext);
    }

    onSubmit = (values) => {
        const { currentStep } = this.props;
        const stepQnA = initStepQnA[currentStep - 1];

        if (values.damagedProperty) {
            stepQnA.questions[0].answers = [values.damagedProperty === 'true' ? 'Yes' : 'No'];
        }

        const answers = [];
        values.registeredVehicle && answers.push('Vehicle/s registered in NSW');
        values.registeredVessel && answers.push('Vessel/s registered in NSW');
        values.noVehicle && answers.push('None of the above');

        stepQnA.questions[1].answers = collectAnswersFromOptions(damagedVehicleOptions, values);

        this.props.nextAction('propertyAndVehicle', values, currentStep, stepQnA);
        this.props.nextStep();
    };

    render () {
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.onSubmit}>
                {({ values, handleChange, setFieldValue }) => (
                    <Form className={classNames('form form-property', { hidden: !this.props.isActive })}>
                        <div className="form-title">Property, vehicles and vessels</div>

                        <FormRadioGroup
                            id="damagedProperty"
                            name="damagedProperty"
                            legend="Has your property been impacted or damaged?"
                            helpMessage="This includes houses, buildings, sheds, fencing, business premises and fallen trees."
                            options={[...yesNoOptions]}
                            value={values.damagedProperty || ''}
                            vertical
                            onChange={value => {
                                setFieldValue('damagedProperty', value);
                                handleChange(value);
                            }}
                        />

                        <ExclusiveCheckboxGroup
                            id="damagedVehicles"
                            legend="Do you have any damaged vehicles or vessels?"
                            helpMessage="Please select all applicable."
                            options={damagedVehicleOptions}
                            formik={{ values, handleChange, setFieldValue, initialValues: initialDamagedVehiclesValues }}
                        />

                        <FooterNav onBackPressed={this.props.previousStep} />
                    </Form>
                )}
            </Formik>
        );
    }
}
