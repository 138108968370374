import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AutoSuggest from 'react-autosuggest';
import { AutoSuggestContainer } from './AutoSuggest.styled';

class AutoSuggestComponent extends Component {
    constructor (props) {
        super(props);
        this.state = { value: '' };
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
        this.props.onValueChange(newValue);
    };

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim();
        if (inputValue.length > 2) {
            this.props.onGetSuggestions(value);
        } else {
            this.props.onClearSuggestions();
        }
    };

    onSuggestionsClearRequested = () => {
        this.props.onClearSuggestions();
    };

    onSuggestionSelected = (_, { suggestion }) => {
        this.props.onSelect(suggestion);
    };

    render () {
        const {
            placeholder,
            suggestions,
            getSuggestionValue,
            renderSuggestion,
            showManualAddress
        } = this.props;

        const value = this.state.value || this.props.initialValue || '';
        const inputProps = { placeholder, value, onChange: this.onChange };

        let finalSuggestions = suggestions;
        if (showManualAddress) {
            finalSuggestions = suggestions.concat({
                address: <>Can’t find your address? &nbsp;<span className='enter-address-manually'>Click here</span></>
            });
        }
        return (
            <AutoSuggestContainer showManualAddress={showManualAddress}>
                <AutoSuggest
                    suggestions={finalSuggestions}
                    getSuggestionValue={getSuggestionValue}
                    inputProps={inputProps}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    renderSuggestion={renderSuggestion}
                />
            </AutoSuggestContainer>
        );
    }
}

AutoSuggestComponent.propTypes = {
    suggestions: PropTypes.array.isRequired,
    onGetSuggestions: PropTypes.func.isRequired,
    onClearSuggestions: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    getSuggestionValue: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
    showManualAddress: PropTypes.bool,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string
};

export default AutoSuggestComponent;
