export default function stripUselessFields(form) {
    return Object.entries(form).reduce((acc, [key, value]) => {
        if (Object.prototype.toString.call(value) === '[object Object]') {
            const entries = Object.entries(value)
                .filter(([innerKey, innerValue]) => !innerKey.includes('-'))
                .reduce((innerAcc, [innerKey, innerValue]) => ({ ...innerAcc, [innerKey]: innerValue }), {})
            ;

            return {
                ...acc,
                [key]: entries
            };
        }

        return {
            ...acc,
            [key]: value
        };
    }, {});
}
