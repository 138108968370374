import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { space } from 'styled-system';
import PropTypes from 'prop-types';

export const AutoSuggestContainer = styled.div`
    ${space};
    font-family: inherit;
    font-weight: inherit;
    height: 3rem;
    border: 2px solid #808080;
    border-radius: 6px;
    padding: 6px 16px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: unset;
    padding-right: unset;
  
    input {
        font-family: inherit;
        width: 100%;
        border: none;
        height: unset;
        text-transform: capitalize;

        :focus {
            outline: none;
        }

        &.react-autosuggest__input {
            border: none;
        }
    }
    
    @media screen and (min-width: 650px) {
        input.react-autosuggest__input {
            max-width: 100%;
        }
    }
    
    div.react-autosuggest__suggestions-container--open {
        position: absolute;
        top: 35px;
        left: 0;
        width: 100%;
        z-index: 100;
        cursor: pointer;
        
        border-radius: 6px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid #808080;
        background-color: #FFFFFF;
        
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            display: flex;
            align-items: center;
            min-height: 1.5rem;
            padding: 8px 0 8px 1rem;
            border-bottom: 1px solid #808080;
            text-transform: capitalize;
          }
          
          ${props => props.showManualAddress && css`
            li:last-child {
                background: blue;
                color: #ffffff;
                
                span {
                   text-decoration: underline;
                }
            }
          `}          
        }
        
        ul > li.react-autosuggest__suggestion--highlighted {
            background-color: #808080;
            
            ${props => props.showManualAddress && css`
              &:last-child{
                background: blue;
              }
            `}
        }
    }
`;

AutoSuggestContainer.propTypes = {
    showManualAddress: PropTypes.bool
};
