import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import Parser from 'html-react-parser';
import './ServiceCategories.scss';
import phoneNumWithTel from '../../../common/phoneNumWithTel';

class Details extends Component {
    static displayName = 'Details';

    static propTypes = {
        services: PropTypes.array.isRequired,
        categoryName: PropTypes.string.isRequired,
        expandedServiceCategoryNames: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        expandedServiceCategoryNames: []
    };

    constructor (props, context) {
        super(props, context);

        this.state = {};
    }

    onClick = (values) => {
        this.setState({});
    };

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    render () {
        const { services, categoryName, expandedServiceCategoryNames } = this.props;

        if (services) {
            return (
                services
                    .sort((service1, service2) => service1.serviceRank - service2.serviceRank)
                    .sort(service1 => service1.isNew ? -1 : 1)
                    .map(service => {
                        return (
                            <div
                                key={`service-item-detail-${categoryName}-${service.provider}-${service.initiativeName}`}
                                className={classNames('grid__container', {
                                    invisible: !expandedServiceCategoryNames.includes(categoryName)
                                })}
                            >
                                <ul className="service-container">
                                    <li className="product-container">
                                        <div className="product">
                                            <div className="product__content">

                                                <h3 className="product__initiative_name">
                                                    {service.furtherInformationLink &&
                                                        <a
                                                            className="button--link button--external"
                                                            title={service.initiativeName}
                                                            href={service.furtherInformationLink}
                                                            target="_blank" rel="noopener noreferrer">
                                                            {service.initiativeName}
                                                        </a>
                                                    }
                                                    {!service.furtherInformationLink &&
                                                        <div>{service.initiativeName}</div>
                                                    }
                                                </h3>

                                                {service.isNew && <span className="new-item">NEW</span>}

                                                {service.formattedExpiryDate && service.formattedExpiryDate !== 0 &&
                                                    <div className="product__description" style={{
                                                        fontWeight: 'bold',
                                                        float: 'right'
                                                    }}>
                                                        {`Ends ${service.formattedExpiryDate}`}
                                                    </div>
                                                }

                                                <div className="product__description">
                                                    {Parser(service.description)}
                                                </div>
                                                {service.eligibility &&
                                            <div className="product__eligibility">
                                                <div style={{ fontWeight: 'bold' }}>Who this is for:</div>
                                                {Parser(service.eligibility)}
                                            </div>
                                                }

                                                <div className="product__footer">
                                                    <div className="product__provider">
                                                        <div style={{ fontWeight: 'bold' }}>{service.provider}</div>
                                                        {service.phoneNumber && phoneNumWithTel(service.phoneNumber)}
                                                    </div>
                                                    {service.furtherInformationLink && (
                                                        <div className="product__further_info">
                                                            <a
                                                                title="Further information"
                                                                className="product__further_info_link button--link button--external"
                                                                href={service.furtherInformationLink}
                                                                target="_blank"
                                                                style={{ paddingRight: 0 }}
                                                                rel="noopener noreferrer"
                                                            >
                                                                Further information
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        );
                    })
            );
        }
        return null;
    }
}

export default Details;
