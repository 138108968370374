import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import StepWizard from '../StepWizard';
import { SurveyFormApi } from './SurveyFormApi';
import Certificates from './certificates/Certificates';
import LivestockForm from './livestock-form/LivestockForm';
import BasicAssistanceForm from './basic-assistance-form/BasicAssistanceForm';
import PropertyForm from './property-form/PropertyForm';
import Header from '../header/Header';
import ConfigurableNotice from '../ConfigurableNotice';
import NaturalDisastersNotice from './NaturalDisastersNotice';
import DisasterTypeForm from './DisasterTypeForm';
import initStepQnA from './InitialValues/stepQnA';
import { smoothScrollTo, stripUselessFields } from '../../common';

const onStepChange = () => {
    smoothScrollTo();
};

const onCancel = () => {
    window.location.href = 'https://www.service.nsw.gov.au/guide/natural-disasters';
};

class SurveyForm extends Component {
    static displayName = 'SurveyForm';

    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired
    };

    constructor (...args) {
        super(...args);

        this.state = {
            form: {
                questionAnswers: initStepQnA
            },
            custom: {
                enterRight: '',
                enterLeft: '',
                exitRight: '',
                exitLeft: ''
            },
            error: '',
            serviceOfferings: []
        };
    }

    updateForm = (key, value, step, questionAnswer) => {
        const form = {
            ...this.state.form,
            [key]: value
        };

        form.questionAnswers[parseInt(step, 10) - 1] = questionAnswer;

        this.setState({ form });
    };

    saveData = async () => {
        const { history } = this.props;
        const { form } = this.state;
        const { disasterType, personalDetails } = form;
        // questionAnswers is an array where each element contains the heading, question text, and answers from each step
        form.questionAnswers = JSON.stringify(form.questionAnswers);

        try {
            const id = await SurveyFormApi.saveSurveyData(disasterType, stripUselessFields(form));

            if (id === 0) {
                this.setState({ error: '' });
            }

            const email = personalDetails && personalDetails.emailAddress ? btoa(personalDetails.emailAddress) : '';
            history.push(`summary/${id}/${email}`);
        } catch (error) {
            history.push('/error');
        }
    };

    renderNotification () {
        if (window.location.href.includes('#step1') || !window.location.href.includes('#step')) {
            return <NaturalDisastersNotice />;
        }

        return null;
    }

    render () {
        return (
            <div>
                <Header title="Disaster Assistance Finder" />
                <div className="container page">
                    <ConfigurableNotice appType="daf" />
                    {this.renderNotification()}
                    <StepWizard
                        transitions={this.state.custom}
                        onStepChange={onStepChange}
                        location={this.props.location}
                    >
                        <DisasterTypeForm nextAction={this.updateForm} previousAction={onCancel} />
                        <BasicAssistanceForm nextAction={this.updateForm} />
                        <Certificates nextAction={this.updateForm} />
                        <PropertyForm nextAction={this.updateForm} />
                        <LivestockForm nextAction={this.updateForm} saveData={this.saveData} />
                    </StepWizard>
                </div>
            </div>
        );
    }
}

export default withRouter(SurveyForm);
