import React from 'react';

const LocationBlue = () => {
    return (
        <svg version="1.1" width="17" viewBox="0 0 17 24" xmlns="http://www.w3.org/2000/svg">
            <title>icon/Location/locator/Blue</title>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-14 -10)" fill="#2E5299" fillRule="nonzero">
                    <g transform="translate(14 10)">
                        <path d="m8.5 0c-4.7338 0-8.5 3.7219-8.5 8.4 0 7.2 8.5 15.6 8.5 15.6s8.5-8.5219 8.5-15.6c0-4.6781-3.7662-8.4-8.5-8.4zm0 11.4c-1.6981 0-3.0357-1.3219-3.0357-3 0-1.6781 1.3376-3 3.0357-3 1.6981 0 3.0357 1.3219 3.0357 3 0 1.6781-1.3376 3-3.0357 3z"/>
                    </g>
                </g>
            </g>
        </svg>);
};
export default LocationBlue;
