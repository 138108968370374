import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import './Unsubscribe.scss';
import { CovidSurveyFormApi } from '../covid-survey-form/CovidSurveyFormApi';

class Unsubscribe extends Component {
    constructor (props) {
        super(props);
        this.state = {
            unsubscribeSuccessful: false
        };
    }

    componentDidMount = async () => {
        const { disasterType, id } = this.props.match.params;
        try {
            const result = await CovidSurveyFormApi.unsubscribe(disasterType, id);
            if (result === 200) {
                window.sessionStorage.setItem('unsubscribe', 'true');
                this.setState({
                    unsubscribeSuccessful: true
                });
            }
        } catch (e) {
            this.props.history.push('/error');
        }
    };

    render () {
        const { disasterType, id } = this.props.match.params;
        const { unsubscribeSuccessful } = this.state;
        if (unsubscribeSuccessful) {
            return <Redirect to={{
                pathname: `/${disasterType}/summary/${id}`
            }} />;
        }
        return <></>;
    }
}

Unsubscribe.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(Unsubscribe);
