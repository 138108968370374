export default function collectAnswersFromOptions(options, values) {
    return options.reduce((acc, { label, value }) => {
        if (values[`${value}`]) {
            return [
                ...acc,
                label
            ];
        }

        return acc;
    }, []);
}
