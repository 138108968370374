import * as React from 'react';
import api from '../../apis/api';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from '../header/Header';

class TransactionReplayForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            records: 0,
            error: ''
        };
    }

    componentDidMount = async () => {
        try {
            const { fromId, toId } = this.props.match.params;
            const records = await api.getSecure(`/saveTransactionLogs?fromId=${fromId}&toId=${toId}`);
            this.setState({ records: records, error: '' });
        } catch (error) {
            this.setState({ records: 0, error: error });
        }
    };

    render () {
        const { records, error } = this.state;
        return (
            <>
                <Header title={'Disaster Assistance Finder'} />
                {
                    error !== '' &&
                    <div>
                        Something went wrong. Please try again.
                        Error message: {error}
                    </div>
                }

                {
                    records > 0 &&
                    <div>
                        Pushed {records} records to transaction log
                    </div>
                }
            </>
        );
    }
}

TransactionReplayForm.propTypes = {
    match: PropTypes.object
};

export default withRouter(TransactionReplayForm);
