const stepQnA = [
    {
        heading: 'Your situation',
        questions: [{
            text: 'What do you need assistance for?',
            answers: []
        }]
    },
    {
        heading: 'About You',
        questions: [{
            text: 'How have you been impacted?',
            answers: []
        },
        {
            text: 'Are you of Aboriginal and/or Torres Strait Islander origin?',
            answers: []
        }]
    },
    {
        heading: 'Documents and Licences',
        questions: [{
            text: 'Do you need to replace any documents or licences?',
            answers: []
        }]
    },
    {
        heading: 'Property, vehicles and vessels',
        questions: [{
            text: 'Has your property been impacted or damaged?',
            answers: []
        },
        {
            text: 'Do you have any damaged vehicles or vessels?',
            answers: []
        }]
    },
    {
        heading: 'Livestock and animals',
        questions: [{
            text: 'Do you have livestock or animals that are injured or affected?',
            answers: []
        }]
    }
];

export default stepQnA;
