import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { Form, Formik } from 'formik';
import { FormRadioGroup } from '@snsw/react-component-library';
import FooterNav from '../../FooterNav/FooterNav';
import initStepQnA from '../InitialValues/stepQnA';
import { yesNoOptions } from '../../../common';
import './LivestockForm.scss';

const initialValues = { livestock: 'initFalse' };

export default class LivestockForm extends Component {
    static displayName = 'LivestockForm';

    static propTypes = {
        previousStep: PropTypes.func,
        nextStep: PropTypes.func,
        nextAction: PropTypes.func,
        isActive: PropTypes.bool,
        currentStep: PropTypes.number,
        saveData: PropTypes.func
    };

    static defaultProps = {
        previousStep: Function.prototype,
        nextStep: Function.prototype,
        nextAction: Function.prototype,
        isActive: false,
        currentStep: 1,
        saveData: Function.prototype
    };

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState) || !isEqual(this.context, nextContext);
    }

    onSubmit = async (values) => {
        const { currentStep } = this.props;
        const stepQnA = initStepQnA[currentStep - 1];

        if (values.livestock !== 'initFalse') {
            stepQnA.questions[0].answers = [values.livestock === 'true' ? 'Yes' : 'No'];
        } else {
            values.livestock = 'false';
        }

        this.props.nextAction('livestock', values, currentStep, stepQnA);
        await this.props.saveData();
    };

    render () {
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.onSubmit}>
                {({ values, handleChange, setFieldValue, isSubmitting }) => (
                    <Form className={classNames('form form-livestock', { hidden: !this.props.isActive })}>
                        <div className="form-title">Livestock and animals</div>

                        <FormRadioGroup
                            id="livestock"
                            name="livestock"
                            legend="Do you have livestock or animals that are injured or affected?"
                            helpMessage="This includes stock, cattle, horses, sheep and domestic pets."
                            onChange={value => {
                                setFieldValue('livestock', value);
                                handleChange(value);
                            }}
                            value={values.livestock || ''}
                            options={[...yesNoOptions]}
                            vertical
                        />

                        <FooterNav
                            onBackPressed={this.props.previousStep}
                            forwardLabel="Show results"
                            forwardLabelDisabled={isSubmitting}
                        />
                    </Form>
                )}
            </Formik>
        );
    }
}
