import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ScrollToError = ({ errors, submitCount, addressRefs }) => {
    const prevSubmitCount = useRef(submitCount);

    useEffect(() => {
        const errorKeys = Object.keys(errors);
        if (prevSubmitCount.current !== submitCount) {
            let element = document.querySelector(`[id*=${errorKeys[0]}]`);

            if (!element && addressRefs?.length > 0) {
                addressRefs.some(address => {
                    if (address.name === errorKeys[0]) {
                        element = address.ref.current.querySelector('input[class*=react-autosuggest]');
                    }
                    return address.name === errorKeys[0];
                });
            }

            element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element?.focus(({ preventScroll: true }));
        }

        return () => {
            prevSubmitCount.current = submitCount;
        };
    }, [submitCount]);

    return null;
};

ScrollToError.propTypes = {
    submitCount: PropTypes.number.isRequired,
    errors: PropTypes.object.isRequired,
    addressRefs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            ref: PropTypes.ref
        })
    )
};

ScrollToError.defaultProps = {
    addressRefs: []
};

export default ScrollToError;
