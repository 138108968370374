import React from 'react';
import PropTypes from 'prop-types';
import AutoSuggestComponent from './AutoSuggestComponent';
import { ErrorMessage } from 'formik';
import addressApi from '../../apis/addressApi';
import addressFetchOrderedApi from '../../apis/addressFetchOrderedApi';
import './AddressInput.scss';

const defaultManualAddressFields = {
    suburb: '',
    state: '',
    postcode: '',
    addressLine: ''
};

const defaultAutoSuggestedAddressFields = {
    unitNumber: '',
    buildingNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    propertyName: '',
    addressLine: '',
    suburb: '',
    state: '',
    country: '',
    postcode: '',
    latitude: '',
    longitude: '',
    nswPointId: '',
    formattedAddress: '',
    validated: true
};

const defaultAddressValidator = (address) => {
    if (address.validated) {
        return true;
    }
    return Object.keys(defaultManualAddressFields).every(key => address[`${key}`]);
};

const statesCodes = [
    { text: '', value: '' },
    { text: 'NSW', value: 'NSW' },
    { text: 'ACT', value: 'ACT' },
    { text: 'QLD', value: 'QLD' },
    { text: 'VIC', value: 'VIC' },
    { text: 'TAS', value: 'TAS' },
    { text: 'SA', value: 'SA' },
    { text: 'NT', value: 'NT' },
    { text: 'WA', value: 'WA' }
];

export default class AddressInput extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            suggestions: [],
            hasReceivedUpdatedPropsOnce: false,
            address: this.props.value,
            userSelected: this.props.value && this.props.value.validated,
            noSuggestionsFromNswPoint: false
        };
    }

    static getDerivedStateFromProps (props, state) {
        if (Object.keys(props.value).length > 0 && !state.hasReceivedUpdatedPropsOnce) {
            return {
                address: props.value,
                hasReceivedUpdatedPropsOnce: true
            };
        }
        return null;
    }

    onSwitchToManual = () => {
        this.setState({
            address: {
                ...defaultManualAddressFields,
                validated: false
            },
            userSelected: false
        });

        this.props.onAddressHasBecomeInvalid();
        this.props.onSwitchToManual(true);
    };

    onSwitchToAutoSuggest = () => {
        this.setState({
            address: {
                ...defaultAutoSuggestedAddressFields,
                validated: true
            },
            userSelected: true
        });

        this.props.onAddressHasBecomeInvalid();
        this.props.onSwitchToAutoSuggest(false);
    };

    handleChange = (key, value) => {
        const updatedAddress = {
            ...this.state.address,
            [key]: value
        };
        this.setState({
            address: updatedAddress
        }, () => {
            if (this.props.validator(updatedAddress)) {
                this.addressHasBecomeValid(updatedAddress, false);
            } else {
                this.props.onAddressHasBecomeInvalid();
            }
        });
        this.props.setFieldValue(key, value);
    };

    fetchSuggestions = async (value) => {
        const response = await addressFetchOrderedApi.getLatestSuggestions(value);
        const { suggestions, isLatest } = response;
        if (suggestions.length === 0) {
            this.setState({
                noSuggestionsFromNswPoint: true
            });
        } else {
            this.setState({
                noSuggestionsFromNswPoint: false
            });
        }
        if (isLatest) {
            this.setState({ suggestions });
        }
    };

    debouncedFetchSuggestions = debounce(this.fetchSuggestions, 500);

    handleChangeAfterSelection = (updatedAddress) => {
        if (!updatedAddress || updatedAddress === '') {
            this.setState({
                address: {},
                userSelected: false
            });
            this.addressHasBecomeValid({}, true);
            return;
        }
        if (this.state.userSelected) {
            if (updatedAddress !== this.state.address.formattedAddress) {
                this.props.onAddressHasBecomeInvalid();
            } else {
                this.addressHasBecomeValid(this.state.address, true);
            }
        }
    };

    addressHasBecomeValid = (updatedAddress, validated) => {
        const completeAddress = { ...defaultAutoSuggestedAddressFields, ...updatedAddress, validated: validated };
        this.props.onAddressHasBecomeValid({ ...completeAddress });
    };

    selectValue = async (value) => { // suggestion select
        const { id, address } = value;
        if (typeof address === 'object') {
            this.onSwitchToManual();
        } else {
            const detailedAddress = await addressApi.getDetailsById(id);
            this.setState({
                address: detailedAddress,
                userSelected: true
            });
            this.addressHasBecomeValid(detailedAddress, true);
        }
    };

    clearSuggestions = () => {
        this.setState({ suggestions: [], address: {} });
    };

    renderSuggestion = ({ address }) => address;

    getSuggestionValue = ({ address }) => {
        if (typeof address === 'string') {
            return address;
        } else {
            return this.props.value.formattedAddress;
        }
    };

    render () {
        const { suggestions, address, noSuggestionsFromNswPoint } = this.state;
        const { addressType, isCovid } = this.props;
        const { addressLine = '', suburb = '', state = '', postcode = '', formattedAddress, validated = true } = address;

        return (
            <div className={`${addressType}-address details page-sub-section${isCovid ? ' covid-address' : ''}`}>
                {
                    !validated
                        ? <div>
                            {isCovid &&
                                <label htmlFor="country" className='covid-label'>{this.props.label}</label>}
                            <div className='address-label' onClick={e => {
                                e.preventDefault();
                            }}>
                                {!isCovid &&
                                    <>
                                        <label htmlFor="address-label">{this.props.label}</label>
                                        <input
                                            type="button"
                                            className="button button--link text-link lighter-text notranslate"
                                            value="Back to search"
                                            onClick={this.onSwitchToAutoSuggest} />
                                    </>
                                }
                            </div>

                            <div className="form__item">
                                {isCovid &&
                                    <div className='address-label' onClick={e => {
                                        e.preventDefault();
                                    }}>
                                        <label htmlFor="addressLine" className='covid-label'>Street Address</label>
                                        <input
                                            type="button"
                                            className={`button  button--link text-link lighter-text${isCovid ? ' manual-mode-text' : ''} notranslate`}
                                            value="Back to search"
                                            onClick={this.onSwitchToAutoSuggest} />
                                    </div>
                                }
                                {!isCovid && <label htmlFor="addressLine">Address Line</label>}
                                <input type="text" id="addressLine" className={`form__text${isCovid ? ' covid-input' : ''}`}
                                    value={addressLine}
                                    onChange={(e) => this.handleChange('addressLine', e.target.value)}/>
                                <ErrorMessage className="form__error" name={'addressLine'} component={'div'}/>
                            </div>

                            <div className="form__item">
                                <label htmlFor="suburb" className={`${isCovid && 'covid-label'}`}>Suburb</label>
                                <input type="text" id="suburb" className={`form__text${isCovid ? ' covid-input' : ''}`} value={suburb}
                                    onChange={(e) => this.handleChange('suburb', e.target.value)}/>
                                <ErrorMessage className="form__error" name={'suburb'} component={'div'}/>
                            </div>

                            <div className="form__item">
                                <label htmlFor="state" className={`${isCovid && 'covid-label'}`}>State</label>
                                <select
                                    id="state"
                                    className={`form__select${isCovid ? ' covid-input' : ''} notranslate`}
                                    name={state}
                                    value={state}
                                    onChange={(e) => this.handleChange('state', e.target.value)}>
                                    {
                                        statesCodes.map((e) => {
                                            return (<option key={e.value} value={e.value}>{e.text}</option>);
                                        })
                                    }
                                </select>
                                <ErrorMessage className="form__error" name={'state'} component={'div'}/>
                            </div>

                            <div className="form__item">
                                <label htmlFor="postcode" className={`${isCovid && 'covid-label'}`}>Postcode</label>
                                <input type="text" id="postcode" className={`form__text${isCovid ? ' covid-input' : ''}`} value={postcode}
                                    onChange={(e) => this.handleChange('postcode', e.target.value)}/>
                                <ErrorMessage className="form__error" name={'postcode'} component={'div'}/>
                            </div>

                        </div>
                        : <div>
                            {isCovid &&
                                <label htmlFor="country" className='covid-label'>{this.props.label}</label>}
                            <div className='address-label' onClick={e => {
                                e.preventDefault();
                            }}>
                                {!isCovid &&
                                    <label htmlFor="country">{this.props.label}</label>}
                                {isCovid &&
                                    <label htmlFor="country" className='helper'>Start typing to search for an address.</label>}
                                <input
                                    type="button"
                                    className={`button  button--link text-link lighter-text${isCovid ? ' manual-mode-text' : ''} notranslate`}
                                    value="Enter address manually"
                                    onClick={this.onSwitchToManual} />
                            </div>
                            <AutoSuggestComponent
                                showManualAddress={false}
                                initialValue={formattedAddress}
                                suggestions={suggestions}
                                onGetSuggestions={this.debouncedFetchSuggestions}
                                onClearSuggestions={this.clearSuggestions}
                                onSelect={this.selectValue}
                                getSuggestionValue={this.getSuggestionValue}
                                onValueChange={e => this.handleChangeAfterSelection(e)}
                                renderSuggestion={this.renderSuggestion}/>
                            <div className={noSuggestionsFromNswPoint || isCovid ? 'hide' : 'show'}>Start typing to search for an address</div>
                            <div className={noSuggestionsFromNswPoint ? 'error' : 'hide'}>Unfortunately we were unable to find your address. Please try again or enter your address manually.</div>
                        </div>
                }
            </div>
        );
    }
}

AddressInput.propTypes = {
    addressType: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    value: PropTypes.shape({
        addressLine: PropTypes.string,
        suburb: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string,
        country: PropTypes.string,
        formattedAddress: PropTypes.string,
        nswPointReferenceId: PropTypes.string,
        validated: PropTypes.bool
    }).isRequired,
    onAddressHasBecomeValid: PropTypes.func.isRequired,
    onAddressHasBecomeInvalid: PropTypes.func.isRequired,
    onSwitchToManual: PropTypes.func.isRequired,
    onSwitchToAutoSuggest: PropTypes.func.isRequired,
    validator: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func,
    isCovid: PropTypes.bool
};

AddressInput.defaultProps = {
    addressType: 'residential',
    validator: defaultAddressValidator,
    onSwitchToManual: () => {
    },
    onSwitchToAutoSuggest: () => {
    },
    isCovid: false
};

function debounce (func, wait) {
    let timerId;
    return function (...args) {
        clearTimeout(timerId);

        timerId = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}
