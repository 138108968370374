import React from 'react';
import Notice from '../../Notice';
import './NaturalDisastersNotice.scss';

export default function NaturalDisastersNotice () {
    const text = 'To view Local Government Areas in NSW that have been declared natural disasters, ';
    const anchor = (
        <a
            className="button--link button--external"
            href="https://www.emergency.nsw.gov.au/Pages/publications/natural-disaster-declarations/2020-2021.aspx"
            rel="noopener noreferrer"
            target="_blank"
            title="Click to view areas in NSW that have been declared natural disasters"
        >
            click here
        </a>
    );

    return (
        <Notice
            message={<span className="natural-disasters-notice">{text}{anchor}</span>}
            title="Disaster affected zones"
            type="info"
        />
    );
}
